/* eslint-disable */
import { IJsonIdentity } from '../interfaces/jsonIdentity'

export interface IPowerbiReport extends IJsonIdentity {
  token: string
  groupId: string
  copy(): any
}

export class PowerbiReport implements IPowerbiReport {
  public id: number
  public token: string
  public groupId: string

  constructor(powerbiReport?: IPowerbiReport) {
    this.id = powerbiReport?.id ?? 0 
    this.token = powerbiReport?.token ?? ''
    this.groupId = powerbiReport?.groupId ?? ''
  } 

  public copy(): PowerbiReport {
    return new PowerbiReport(this)
  }

  public isEqual(powerbiReport: PowerbiReport) {
    return this.id == powerbiReport.id
      && this.token == powerbiReport.token
      && this.groupId == powerbiReport.groupId

  }


}
