

  import { Component, Vue } from 'vue-property-decorator'
  import { ReportingState } from '@/ts/states/reporting/reportingState'
  import HeadPanel from '@/components/panels/HeadPanel.vue'

  @Component({
    components: {
      HeadPanel
    },
  })

  export default class Reporting extends Vue {

    /** PUBLIC PROPERTIES */

    /** PRIVATE PROPERTIES */
    protected state = new ReportingState(this.$store)

    /** LIFECYCLE */

    public mounted() {
      this.embedReport()
    }

    protected async embedReport() {

      const reportContainer = document.getElementById('container')

      const report = await this.state.getReport(reportContainer!)

        report!.off('loaded')
        report!.off('rendered')
        report!.on('error', () => {
          report!.off('error')
        })
    }
  }

