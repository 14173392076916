
  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component

  export default class BackToLink extends Vue {

  /** PROPERTIES */

    @Prop({ default: 'text-white' })
    public textColour!: string

    @Prop()
    public to!: string
  }

