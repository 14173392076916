import { ApiRepository } from './apiRepository'
import { Paths } from '../constants/paths'
import { HttpStatus } from '../enums/httpStatusCode'
import { PowerbiReport, IPowerbiReport } from '../models/powerbiReport'
import axios from 'axios'

export class ReportRepository extends ApiRepository<PowerbiReport, IPowerbiReport> {

  constructor(path = '', type: new (i?: IPowerbiReport) => PowerbiReport = PowerbiReport) {
    super(path, type)
  }

  public async getAuthentication(): Promise<string> {
    return await this.getAuthenticationToken()
  }

  public async getAuthenticationToken(): Promise<string> {

    const url = this.urlPath(this.path, null, [Paths.REPORTS])
    const response = await axios.get(url)

    if (!HttpStatus.isOK(response.status)) {
      throw response.status
    }

    return response.data
  }


}


