
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Route } from '@/ts/models/route'

  import BackToLink from '../links/BackToLink.vue'

  @Component({
    components: {
      BackToLink
    }
  })
  export default class Banner extends Vue {

  /** PROPERTIES */

    @Prop({ default: false })
    public fullscreen!: boolean

    @Prop({ default: null })
    public route!: Route | null

    @Prop({ default: '' })
    public title!: string


    /** COMPUTED PROPERTIES */

    protected get hasRoute() {
      return this.route != null
    }

    protected get returnRoute() {
      return (this.hasRoute) ? this.route!.toPath() : '#'
    }
  }


