import { Store } from 'vuex'
import { Route } from '@/ts/models/route'
import { ViewStatus } from '@/ts/enums/viewStatus'
import { ReportRepository } from '@/ts/repositories/reportRepository'
import * as pbi from 'powerbi-client'
import _ from 'lodash'
import { Embed } from 'embed'

//export class PbiEmbed extends Embed {


//}

export class ReportingState {

  /** PUBLIC PROPERTIES */

  /**
   * The current status
   * @property
   * @see {@link ViewStatus}
   * @returns {ViewStatus}
   */
  public status = ViewStatus.NONE


  /** PRIVATE PROPERTIES */

  private routes: Route[]
  private store: Store<any>

  /**
* The repository to fetch PowerBi token
 * @private
 * @property
 * @see {@link ReportRepository}
 * @returns {ReportRepository}
 */
  private repo = new ReportRepository()

  /** CONSTRUCTORS */

  constructor(store: Store<any>) {
    this.routes = new Array<Route>()
    this.store = store
   }

/** PUBLIC METHODS */

  public popRoute() {
    const route = _.last(this.routes)
    return route
  }

  public pushRoute(route: Route) {
    this.routes.push(route)
  }

  public async getReport(reportContainer: any)  {

    try {
      const embedToken = await this.getAuthentication()
      const reportId = 'ce46e274-8427-4fd2-969e-061dfbac5f0a'

      const embedConfiguration = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: embedToken,
        embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=ce46e274-8427-4fd2-969e-061dfbac5f0a&groupId=604eb8c1-7fb9-4079-ba98-38ef1f640ebd',
        id: reportId,
        pageView: 'oneColumn', //"fitToWidth" | "oneColumn" | "actualSize"
        permissions: pbi.models.Permissions.All,
        layoutType: pbi.models.LayoutType.Master,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: true,
        }
      }

      const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory)
      return powerbi.embed(reportContainer! as HTMLElement, embedConfiguration) as Embed

    }

    catch (error) {
      this.status = ViewStatus.FAILED
      console.log('PbiReport:', error)
    }

  }

  private async getAuthentication() {
    try {
      const response = await this.repo.getAuthentication()
      this.status = ViewStatus.SUCCEEDED
      return response

    }
    catch (error) {
      this.status = ViewStatus.FAILED
      console.log(error)
    }

  }
}
